import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css'
import Home from './pages/Home';
import Create from './pages/Create';
import EditNews from './pages/EditNews';
import Login from './pages/Login';
import PublishedNews from './pages/PublishedNews';
import DraftNews from './pages/DraftNews';
import DeletedNews from './pages/DeletedNews';
import Profile from './pages/Profile';
import Sitemap from './pages/Sitemap';
import Category from './pages/admin/Category';
import Users from './pages/admin/Users';
import Advertisement from './pages/admin/Advertisement';
import Settings from './pages/admin/Settings';
import Logout from './pages/Logout';
import ComingSoon from './pages/ComingSoon';
import Report from './pages/editor/Report';
import UserReport from './pages/editor/UserReport';
import Setup from './pages/Setup';
import PrivateRoute from './routes/PrivateRoute';
const App = () => {

  return (
    <div>

      <Router>
        <Route path="/" exact render={(props) => <Login />} />

        <PrivateRoute path="/category" component={Category} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/advertisement" component={Advertisement} />
        <PrivateRoute path="/homemetainfo" component={Category} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/document" component={Category} />

        <PrivateRoute path="/dashboard" component={Home} />
        <PrivateRoute path="/addNews" component={Create} />
        <PrivateRoute path="/editNews/:id" component={EditNews} />
        <PrivateRoute path="/publishedNews" component={PublishedNews} />
        <PrivateRoute path="/draftNews" component={DraftNews} />
        <PrivateRoute path="/deletedNews" component={DeletedNews} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/sitemap" component={Sitemap} />
        <PrivateRoute path="/report" component={Report} />
        <PrivateRoute path="/userreport" component={UserReport} />
        <PrivateRoute path="/comingSoon" component={ComingSoon} />





      </Router>

    </div>
  )
}
export default App;

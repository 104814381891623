import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import setting from '../setting.json';



const PublishedItems = ({ startDate, endDate, search, category }) => {
    const [list, setList] = useState(null);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('user')).id);
    const [show, setShow] = useState(false);
    const [toastShow, setToastShow] = useState(false);

    const [deleteId, setDeleteId] = useState(0);
    useEffect(() => {
        getPublishedNewsCount();
    }, []);

    useEffect(() => {
        getNews();
        getPublishedNewsCount();
    }, [newsCount]);

    const getNews = async () => {
        //console.log(startDate);

        var url = setting.api;
        const formData = new FormData();
        formData.append("page", currentPage);
        formData.append("start", startDate);
        formData.append("end", endDate);
        formData.append("userid", userId);
        formData.append("search", search);
        formData.append("category", category);
        await fetch(
            url + '/api/getPublishedNews/',
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                setList(json);

            }
            );
    }
    const getPublishedNewsCount = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("start", startDate);
        formData.append("end", endDate);
        formData.append("userid", userId);
        formData.append("search", search);
        formData.append("category", category);
        //console.log(formData);
        await fetch(url + '/api/getPublishedNewsCount',
            {
                method: "POST",
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                setNewsCount(json);
                setPageCount(Math.ceil(json / 10));
            }
            );
    }
    const showPopUp = async (event) => {
        var id = event.target.getAttribute('data');
        setDeleteId(id);
        setShow(true);
    }
    const cancelDelete = async (event) => {
        setShow(false);
        setDeleteId(0);
    }

    const deleteNews = async (event) => {
        console.log(deleteId);
        var url = setting.api;
        await fetch(url + '/api/deleteNews/' + deleteId)
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                if (json === 1) {
                    setNewsCount(newsCount - 1);
                    setShow(false);
                    setDeleteId(0);
                    setToastShow(true);
                }
            }
            );
    }
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }


    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }
    useEffect(() => {
        //console.log('useeffect run');
        getNews();

    }, [currentPage]);

    useEffect(() => {
        getNews();
        getPublishedNewsCount();

    }, [startDate, endDate, search]);



    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Author</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!list &&
                        <tr>
                            <td id="norecord">No Record found</td>
                        </tr>
                    }

                    {list && list.map((r) => (
                        <tr key={r.id}>
                            <td>{r.id}</td>
                            <td><a href={`${setting.url}/${r.slug}`} target="_blank" rel="noreferrer">{r.title}</a></td>
                            <td>{r.cats}</td>
                            <td>{r.user}</td>
                            <td>{r.pubDate}</td>
                            <td>
                                <Button variant="info" size="sm"><Link id="edit" to={`/editNews/${r.id}`} >Edit</Link></Button>
                                <Button className="delete" size="sm" variant="warning" data={r.id} onClick={showPopUp}>Delete</Button>
                            </td>
                        </tr>
                    ))}


                </tbody>
            </Table>
            <Modal show={show} >
                <Modal.Header closeButton>
                    <Modal.Title>Delete News</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete news ? Y/N</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelDelete} >
                        No
                    </Button>
                    <Button variant="primary" onClick={deleteNews}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                newsCount &&
                <Row>
                    <Col></Col>
                    {
                        currentPage !== 1 ?
                            <Col >
                                <Button className="center" onClick={clickFirst}>First</Button>{' '}
                                <Button className="center" onClick={clickPrev}>Prev</Button>
                            </Col> : <Col></Col>
                    }

                    <Col ><p className="center">Page {currentPage} /{pageCount}</p></Col>
                    {
                        currentPage !== pageCount ?
                            <Col >

                                <Button className="center" onClick={clickNext}>Next</Button>{' '}
                                <Button className="center" onClick={clickLast}>Last</Button>
                            </Col> : <Col></Col>
                    }
                    <Col></Col>
                </Row>
            }
            <ToastContainer position="bottom-end" className="p-3">
                <Toast bg='warning' onClose={() => setToastShow(false)} show={toastShow} delay={3000} autohide >
                    <Toast.Body>News deleted successfully</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default PublishedItems;
import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import setting from '../../setting.json';
import Topbar from "../../components/Topbar";

const Category = () => {
    const [list, setList] = useState('');
    const [parent, setParent] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }


    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }


    useEffect(() => {
        getCategories();
    }, [count]);
    useEffect(() => {
        getCategories();
    }, [currentPage]);
    const getCategories = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("page", currentPage);
        await fetch(url + '/api/getCategoryList',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                var i = 1;
                const listItems = json.map((r) =>
                    <tr key={r.id}>
                        <td>{i++}</td>
                        <td><img width="30px" src={`${url}/wp-content/uploads/category/${r.image}`} alt="text" /></td>
                        <td>{r.name}</td>
                        <td>{r.parent}</td>
                        <td>{r.show_in_nav === '1' ? 'yes' : 'no'}</td>
                        <td>{r.position}</td>
                        <td>
                            <Link to={`/category/edit/${r.id}`} id="edit">Edit</Link>{' '}
                            <Link variant="danger" id="delete">Delete</Link>
                        </td>
                    </tr >
                );
                setList(listItems);
            }
            );
    }
    const getParentCategories = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("page", currentPage);
        await fetch(url + '/api/getParentCategories',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                var i = 1;
                const listItems = json.map((r) =>
                    <tr key={r.id}>
                        <td>{i++}</td>
                        <td><img width="30px" src={`${url}/wp-content/uploads/category/${r.image}`} alt="text" /></td>
                        <td>{r.name}</td>
                        <td>{r.parent}</td>
                        <td>{r.show_in_nav === '1' ? 'yes' : 'no'}</td>
                        <td>{r.position}</td>
                        <td>
                            <Link to={`/category/edit/${r.id}`} id="edit">Edit</Link>{' '}
                            <Link variant="danger" id="delete">Delete</Link>
                        </td>
                    </tr >
                );
                setList(listItems);
            }
            );
    }
    const getCategoryCount = async () => {
        var url = setting.api;
        await fetch(url + '/api/getCategoryCount')
            .then(response => response.json())
            .then(json => {
                setCount(json);
                setPageCount(Math.ceil(json / 10));
            }
            );
    }
    useEffect(() => {
        getCategoryCount();
        getParentCategories();
    }, []);

    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Col md={2} className="navbar">
                    <Sidebar />
                </Col>
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Category</h2>
                        <Row>
                            <Col md={4} lg={4}>

                                <Card>
                                    <Card.Body>
                                        <Card.Title>Add Category</Card.Title>
                                        <Row>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Parent</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Select aria-label="Default select example">
                                                        <option>As Parent</option>
                                                        {parent && parent.map((r) =>
                                                            <option value={r.id}>{r.name}</option>
                                                        )}


                                                    </Form.Select>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Type</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Select aria-label="Default select example">

                                                        <option value="1">Normal</option>
                                                        <option value="2">State</option>
                                                        <option value="3">City</option>
                                                        <option value="4">Video</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Name</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="text" placeholder="Enter Name" />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Slug</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="text" placeholder="Enter Slug" />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Image</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="file" placeholder="Enter email" />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Show in Menu</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Select aria-label="Default select example">

                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>

                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Position</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="email" placeholder="Enter Position" />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Meta Title</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="email" placeholder="Enter Meta Title" />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Meta Keyword</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="email" placeholder="Enter Meta Keyword" />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Meta Description</Form.Label>

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">

                                                    <Form.Control type="email" placeholder="Enter Meta Description" />

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md={8} lg={8}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Category</Card.Title>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Image</th>
                                                    <th>Name</th>
                                                    <th>Parent</th>
                                                    <th>Menu</th>
                                                    <th>Position</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col></Col>
                                            {
                                                currentPage !== 1 ?
                                                    <Col >
                                                        <Button className="center" onClick={clickFirst}>First</Button>{' '}
                                                        <Button className="center" onClick={clickPrev}>Prev</Button>
                                                    </Col> : <Col></Col>
                                            }

                                            <Col ><p className="center">Page {currentPage} /{pageCount}</p></Col>
                                            {
                                                currentPage !== pageCount ?
                                                    <Col >

                                                        <Button className="center" onClick={clickNext}>Next</Button>{' '}
                                                        <Button className="center" onClick={clickLast}>Last</Button>
                                                    </Col> : <Col></Col>
                                            }
                                            <Col></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
                {JSON.parse(localStorage.getItem('user')).role !== 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Not Allowed</h2>
                    </Col>
                }
            </Row>


        </Container>

    );
}

export default Category;
import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Form, Button, Table, Badge } from 'react-bootstrap';
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState, useEffect } from "react";
import setting from '../../setting.json';

const UserReport = () => {
    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [user, setUser] = useState(0);
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const updateStartDate = () => {
        var startd = document.getElementById('startd').value;
        setFromDate(startd);

    }
    const updateEndDate = () => {
        var endd = document.getElementById('endd').value;

        setToDate(endd);
    }
    const updateUser = () => {
        var userid = document.getElementById('userid').value;
        setUser(userid);
    }
    const getUsersList = async () => {
        var url = setting.api;
        await fetch(url + '/api/getAllUsers/')
            .then(response => response.json())
            .then(json => {
                setList(json);
            }
            );
    }
    const getUserNewsDataById = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("fromdate", fromDate);
        formData.append("todate", toDate);
        formData.append("userid", user);
        await fetch(url + '/api/getUserNewsDataById/', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(json => {
                setData(json);
                console.log(json);


            }
            );
    }

    useEffect(() => {
        getUsersList();
    }, []);
    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                <Col md={10} lg={10} xs={12} className="rightsect">
                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Generate Report(Single User)</h3>
                    <Card >
                        <Card.Body>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className="mb-3" >
                                        <Form.Control
                                            size="md"
                                            type="date"
                                            value={fromDate}
                                            onChange={updateStartDate}
                                            id="startd"

                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            size="md"
                                            type="date"
                                            value={toDate}
                                            onChange={updateEndDate}
                                            id="endd"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Select aria-label="Default select example" id="userid" onClick={updateUser} required >
                                            <option value="">Select User</option>
                                            {list && list.map((r) =>
                                                <option value={r.id} key={r.id}>{r.fname}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="d-grid gap-2">
                                        <Button variant="primary" size="md" onClick={getUserNewsDataById}>Search</Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row>
                        <Col md={12} lg={12}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Report {data && <span>{data.fromdate} - {data.todate}</span>} </Card.Title>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Location</th>
                                                <th>No. Of News</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <th><img width="80px" src={data.image} alt="" /></th>
                                                <th>{data.name}</th>
                                                <th>{data.email}</th>
                                                <th>{data.role}</th>
                                                <th>{data.location}</th>
                                                <th> <Badge bg="primary">{data.news}</Badge></th>
                                                <th>{data.status}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Footer />
        </Container>
    );
}

export default UserReport;
import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from "react";

import setting from '../../setting.json';
import Topbar from "../../components/Topbar";

const Users = () => {
    const [list, setList] = useState('');
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }


    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }


    useEffect(() => {
        getUsers();
    }, [count]);
    useEffect(() => {
        getUsers();
    }, [currentPage]);
    const getUsers = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("page", currentPage);
        await fetch(url + '/api/getUsers',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                var i = 1;
                const listItems = json.map((r) =>
                    <tr key={r.id}>
                        <td>{i++}</td>
                        <td>{r.name}</td>
                        <td>{r.email}</td>
                        <td>{r.role}</td>
                        <td>{r.location}</td>
                        <td>{r.status}</td>

                        <td>
                            <Button variant="warning" id="edit">Edit</Button>{' '}
                            <Button variant="danger" id="edit">Delete</Button>
                        </td>
                    </tr>
                );
                setList(listItems);
            }
            );
    }
    const getUserCount = async () => {
        var url = setting.api;
        await fetch(url + '/api/getUserCount')
            .then(response => response.json())
            .then(json => {
                setCount(json);
                setPageCount(Math.ceil(json / 10));
            }
            );
    }
    useEffect(() => {
        getUserCount();
    }, []);

    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Col md={2} className="navbar">
                    <Sidebar />
                </Col>
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Users</h2>
                        <Row>

                            <Col md={12} lg={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Users</Card.Title>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Role</th>
                                                    <th>Location</th>
                                                    <th>Status</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col></Col>
                                            {
                                                currentPage !== 1 ?
                                                    <Col >
                                                        <Button className="center" onClick={clickFirst}>First</Button>{' '}
                                                        <Button className="center" onClick={clickPrev}>Prev</Button>
                                                    </Col> : <Col></Col>
                                            }

                                            <Col ><p className="center">Page {currentPage} /{pageCount}</p></Col>
                                            {
                                                currentPage !== pageCount ?
                                                    <Col >

                                                        <Button className="center" onClick={clickNext}>Next</Button>{' '}
                                                        <Button className="center" onClick={clickLast}>Last</Button>
                                                    </Col> : <Col></Col>
                                            }
                                            <Col></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
                {JSON.parse(localStorage.getItem('user')).role !== 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Not Allowed</h2>
                    </Col>
                }
            </Row>


        </Container>

    );
}

export default Users;
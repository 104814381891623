import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from "react";

import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
const Category = () => {
    const [list, setList] = useState('');

    useEffect(() => {
        getNews();
    }, []);
    const getNews = async () => {
        var url = setting.api;
        await fetch(url + '/api/getCategoryList')
            .then(response => response.json())
            .then(json => {
                var i = 1;
                const listItems = json.map((r) =>


                    <tr key={r.id}>
                        <td>{i++}</td>
                        <td><img src={`{url}/wp-content/uploads/category/{r.image}`} alt="text" /></td>
                        <td>{r.name}</td>
                        <td>{r.parent}</td>
                        <td>{r.show_in_nav === '1' ? 'yes' : 'no'}</td>
                        <td>{r.position}</td>
                        <td>
                            <Button variant="warning" id="edit">Edit</Button>{' '}
                            <Button variant="danger" id="edit">Delete</Button>
                        </td>
                    </tr>

                );
                setList(listItems);
            }
            );
    }
    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Col md={2} className="navbar">
                    <Sidebar />
                </Col>
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Category</h2>
                        <Row>
                            <Col md={3} lg={4}>
                                <Card >
                                    <Card.Body>
                                        <Card.Title>Add Category</Card.Title>
                                        <Form.Group as={Row} className="mb-3" controlId="title">
                                            <Form.Label column lg={4}>Email address</Form.Label>
                                            <Col lg={8}>
                                                <Form.Select lg={8} aria-label="Default select example" >
                                                    <option>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </Col>

                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text id="inputGroup-sizing-sm">Name</InputGroup.Text>
                                                <Form.Control
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text id="inputGroup-sizing-sm">Slug</InputGroup.Text>
                                                <Form.Control
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text id="inputGroup-sizing-sm">Position</InputGroup.Text>
                                                <Form.Control
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text id="inputGroup-sizing-sm">Meta Title</InputGroup.Text>
                                                <Form.Control
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <InputGroup size="sm" className="mb-3">
                                                <Form.Label id="inputGroup-sizing-sm">Meta keyword</Form.Label>
                                                <Form.Control
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text id="inputGroup-sizing-sm">Meta description</InputGroup.Text>
                                                <Form.Control
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Control type="text" placeholder="Enter title" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Control type="text" placeholder="Enter title" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Control type="text" placeholder="Enter title" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Control type="text" placeholder="Enter title" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="subtitle">

                                            <Form.Control type="text" placeholder="Enter sub title" />
                                        </Form.Group>


                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={8} lg={8}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Category</Card.Title>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Image</th>
                                                    <th>Name</th>
                                                    <th>Parent</th>
                                                    <th>Menu</th>
                                                    <th>Position</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
                {JSON.parse(localStorage.getItem('user')).role !== 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Not Allowed</h2>
                    </Col>
                }
            </Row>


        </Container>

    );
}

export default Category;